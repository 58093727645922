import { createTheme } from '@mui/material/styles';

function pxToRem(value) {
  return `${value / 16}rem`;
}

let theme = createTheme({
  typography: {
    fontFamily: 'azo-sans-web, sans-serif',
    fontWeight: '400',
    htmlFontSize: 16,
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#0B1B2E',
    },
    secondary: {
      main: '#6CC8EC',
    },
    background: {
      default: '#fff',
      primary: '#0D1E32',
      secondary: '#061B32',
      paper: '#F2F2F2',
      hover: '#6CC8EC',
      focus: '#4FA8CB',
    },
    text: {
      primary: '#0D1E32',
      secondary: '#0D1E32',
      lgrey: '#8FA1BD',
      xlgrey: '#CDCDCD',
      lblue: '#86D8F2',
      dblue: '#2E70DC',
      white: '#FFF',
      black: '#061B32',
      blueblack: '#0C1D30',
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('md')]: {
            paddingLeft: '24px',
            paddingRight: '24px',
          },
          [theme.breakpoints.up('md')]: {
            paddingLeft: '6vw',
            paddingRight: '6vw',
          },
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'subtitle3' },
          style: {
            fontFamily: 'azo-sans-web, sans-serif',
            fontSize: pxToRem(16),
            fontWeight: '300',
            [theme.breakpoints.down('md')]: {
              fontSize: pxToRem(14),
            },
          },
        },
        {
          props: { variant: 'subtitle4' },
          style: {
            fontFamily: 'azo-sans-web, sans-serif',
            fontSize: pxToRem(14),
            fontWeight: '500',
          },
        },
        {
          props: { variant: 'h2-variant' },
          style: {
            fontFamily: 'azo-sans-web, sans-serif',
            fontWeight: '500',
            fontSize: pxToRem(40),
            [theme.breakpoints.down('md')]: {
              fontSize: pxToRem(24),
            },
          },
        },
      ],
      styleOverrides: {
        h1: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '500',
          fontSize: pxToRem(50),
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(40),
          },
        },
        h2: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '500',
          fontSize: pxToRem(40),
        },
        h3: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '500',
          fontSize: pxToRem(32),
        },
        h4: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '700',
          fontSize: pxToRem(15),
          letterSpacing: '2px',
        },
        h5: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '500',
          fontSize: pxToRem(24),
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(16),
          },
        },
        h6: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '400',
          fontSize: pxToRem(20),
        },
        subtitle1: {
          fontSize: pxToRem(22),
        },
        subtitle2: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontSize: pxToRem(16),
          fontWeight: '500',
        },
        body1: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontSize: pxToRem(16),
          fontWeight: '300',
          marginBottom: '1rem',
        },
        body2: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontSize: pxToRem(14),
          fontWeight: '300',
        },
        caption: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '500',
          fontSize: pxToRem(12),
        },
        button: {
          fontFamily: 'azo-sans-web, sans-serif',
          fontWeight: '700',
          fontSize: pxToRem(16),
        },
      },
    },
  },
});

//theme = responsiveFontSizes(theme, { factor: 5, variants: ['h1', 'body2'] });

export default theme;
