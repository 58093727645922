const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MENU':
      return {
        ...state,
        menu: action.payload,
      };
    case 'SET_BODY_FIXED':
      return {
        ...state,
        body_fixed: action.body_fixed,
      };
    case 'FILTER_LOCATION':
      return {
        ...state,
        filter_location: action.payload,
      };
    case 'FILTER_DEPARTMENT':
      return {
        ...state,
        filter_department: action.payload,
      };
    case 'FILTER_TYPE':
      return {
        ...state,
        filter_type: action.payload,
      };
    case 'SET_ROLE_SEARCH':
      return {
        ...state,
        role_search: action.payload,
      };
    case 'SET_DEPTARTMENT_PARAM':
      return {
        ...state,
        department_param: action.payload,
      };
    case 'SET_FILTER_RESET':
      return {
        ...state,
        reset_filters: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;
