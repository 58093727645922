import React, { createContext, useReducer } from 'react';
import Reducer from './Reducer';
import PropTypes from 'prop-types';

const initialState = {
  menu: false,
  filter_location: [],
  filter_department: [],
  filter_type: [],
  role_search: '',
  department_param: '',
  reset_filters: false,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Context = createContext(initialState);
export default Store;
