module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"kaluza-careers","accessToken":"MC5aZ0duRnhJQUFDZ0ExWjdY.e--_ve-_vRDvv73vv73vv73vv70qBGwCTu-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_ve-_vTnvv73vv70ceO-_ve-_ve-_vTrvv70","toolbar":"new","imageImgixParams":{"auto":"compress,format","q":100},"imagePlaceholderImgixParams":{"w":100,"blur":15,"q":100},"lang":"en-gb","promptForAccessToken":true,"apiEndpoint":"https://kaluza-careers.cdn.prismic.io/api/v2","pageSize":100},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"bgc8axv"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
